import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/styles";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import {
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Chip,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { instance } from "../../common/apiStructure/axios";
import {
  SET_DRAFT_MESSAGE,
  SET_DRAFT_RECIPIENTS,
  SET_PARTICULAR_PROGRAM,
  SET_PARTICULAR_CLIENT_DATA,
} from "../../../redux/actions";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DialogBox from "../../common/Dialog/DialogBox";
import SuccessDialogBox from "../../common/Dialog/Success-Dialog/SuccessDialogBox.js";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import { errorToaster, successToaster } from "../../../utils/CommonFunctions";
import moment from "moment";
import {
  authRequests,
  programRequests,
  clientRequests,
} from "../../common/apiStructure/requests";
import AppHeader from "../../common/appHeader/AppHeader";
import Home from "../client";
import "./CreateDraft.scss";
import Menubar from "../../common/menubar/Menubar";
import { MyDoc } from "./GeneratePDF";
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

// const CheckboxLable = makeStyles((theme) => ({
//   label: {
//     fontSize: "1.1rem !important",
//     fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
//     fontWeight: "bold",
//   },
// }));
function CreateDraft({ selfDraft }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const classes = CheckboxLable();
  const history = useHistory();
  const auth = useSelector((state) => state.auth);
  const draftMsg = useSelector((state) => state.draftMsg);
  const ActionButton = useSelector((state) => state.actionSummaryButton);
  const draftRecipients = useSelector((state) => state.draftRecipients);
  const client = useSelector((state) => state.particularClientData);
  console.log(client, "client");

  const VisitsData = useSelector((state) => state.setParticularClient.Visits);
  const program = useSelector((state) => state.particularProgramData);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [freeOnlineProgram, setFreeOnlineProgram] = useState(false);
  const [recipients, setRecipients] = useState(draftRecipients);
  const [onlineProgram, setOnlineProgram] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [ErrorForEmailChip, setErrorForEmailChip] = useState(false);
  const [ErrorDataForEmailChip, setErrorDataForEmailChip] = useState("");
  const [tempNameForProgram, setTempNameForProgram] = useState("");
  const [checkStatus, setCheckStatus] = useState(true);
  const [visits, setVisits] = useState(VisitsData);
  const [selectedProgram, setSelectedProgram] = useState("online program");
  const [successModalMsg, setSuccessModalMsg] = useState();
  const language = useSelector((state) => state.language);
  const editClientIdx = useSelector((state) => state.editClientIdx);
  const ClientData = useSelector((state) => state.setParticularClient);
  const OptimumMindVisits = useSelector(
    (state) => state.setParticularClient.OptimumMindVisits ?? []
  );
  const [programLimit, setProgramLimit] = useState(0);
  const [selectedClientForProgram, setSelectedClientForProgram] =
    useState(null);
  const [loader, setLoader] = useState(false);
  const [clientList, setClientList] = useState([]);
  const TwoMonths = moment().add(2, "months").format("YYYY-MM-DDThh:mm:ss");

  const subStartDate = moment().format("YYYY-MM-DDThh:mm:ss");

  const piercing =
    ClientData.piercing && ClientData.piercing.length > 0
      ? ClientData.piercing
      : "";
  let piercingData =
    typeof piercing === "string" ? piercing.split(",") : piercing;
  const piercingValue =
    piercingData.length > 0
      ? piercingData.length === 2
        ? "Remove navel and tongue piercing"
        : piercingData.filter(
            (data) =>
              t(data) === (language == "english" ? "In the navel" : "I naveln")
          ).length > 0
        ? "Remove the navel piercing"
        : piercingData.filter(
            (data) =>
              t(data) === (language == "english" ? "In the tongue" : "I tungan")
          ).length > 0
        ? "Remove the tongue piercing"
        : ""
      : "";
  const waterIntakeAndCoffeeValue =
    ClientData &&
    (ClientData.nonCarbonatedWater === "0-1 liters" ||
      ClientData.nonCarbonatedWater === "0-1 liter") &&
    (ClientData.Coffee === "4 cups or more" ||
      ClientData.Coffee === "4 koppar eller mer")
      ? "Recommend to drink at least 1,5l water less than 3 cups of coffee per day"
      : ClientData.nonCarbonatedWater === "0-1 liters" ||
        ClientData.nonCarbonatedWater === "0-1 liter"
      ? "Recommend to drink at least 1,5l pure water per day"
      : ClientData.Coffee === "4 cups or more" ||
        ClientData.Coffee === "4 koppar eller mer"
      ? "Recommend to drink less than 3 cups of coffee per day"
      : "";
  const sleepValue =
    ClientData &&
    (ClientData.SleepWellCheck === "Poor" ||
      ClientData.SleepWellCheck === "Dålig")
      ? "Tips for improved sleep"
      : "";
  const habitualValue =
    ClientData.habitualPositionCheck &&
    ClientData.habitualPositionCheck.length > 0
      ? "Tips about awareness of and changing habitual positions"
      : "";
  const monotonouseValue =
    ClientData && ClientData.monotonouseTaskDesc !== ""
      ? "Tips about possible different ways to do the monotonous tasks"
      : "";
  const workdayValue =
    ClientData.workingDaysCheck && ClientData.workingDaysCheck.length > 0
      ? "Tips about workrelated pause exercises"
      : "";

  const EFTsymptomProblem =
    ClientData.problemData &&
    ClientData.problemData.map((data) => {
      const { NeckAndShoulder, BackPilvesAndHips, LegsAndFeet, mentalTrauma } =
        data;
      const Problem = `${
        (NeckAndShoulder !== undefined && mentalTrauma === "Yes"
          ? NeckAndShoulder.toLowerCase()
          : "") ||
        (BackPilvesAndHips !== undefined && mentalTrauma === "Yes"
          ? BackPilvesAndHips.toLowerCase()
          : "") ||
        (LegsAndFeet !== undefined && mentalTrauma === "Yes"
          ? LegsAndFeet.toLowerCase()
          : "")
      }`;
      return Problem;
    });
  const eftProblem = _.compact(EFTsymptomProblem);

  const IRTsymptomProblem =
    ClientData.problemData &&
    ClientData.problemData.map((data, index) => {
      const {
        NeckAndShoulder,
        BackPilvesAndHips,
        LegsAndFeet,
        physicalTrauma,
      } = data;
      const Problem = `${
        (NeckAndShoulder !== undefined && physicalTrauma === "Yes"
          ? NeckAndShoulder.toLowerCase()
          : "") ||
        (BackPilvesAndHips !== undefined && physicalTrauma === "Yes"
          ? BackPilvesAndHips.toLowerCase()
          : "") ||
        (LegsAndFeet !== undefined && physicalTrauma === "Yes"
          ? LegsAndFeet.toLowerCase()
          : "")
      }`;
      return Problem;
    });
  const irtProblem = _.compact(IRTsymptomProblem);

  // const AddNextVisit = () => {
  //   let temp = {
  //     daily_note: "",
  //     isSubmitted: false,
  //     painAndFunctionDesc: visits[visits.length - 1].painAndFunctionDesc,
  //     Medication:
  //       visits.length > 0
  //         ? visits[visits.length - 1].Medication
  //         : client && client.medicationsList,
  //     visitNo: visits && visits.length > 0 ? visits.length + 1 : "",
  //     AddressTheLegsAndHips: "",
  //     AddressTheLegsAndHipsExercise: [],
  //     PosturalChain: "",
  //     PosturalChainExercise: [],
  //     PosturalChainTreatment: [],
  //     MotorIntegration: "",
  //     AllTreatments: [],
  //     AddressTheNeckTreatment: [],
  //     AddressTheFrontalPlaneExercise: [],
  //     AddressTheSagittalPlaneExercise: [],
  //     AddressTheArmsShoulderExercise: [],
  //     AddressThoracicRotationExercise: [],
  //     AddressTheArmsShoulderTreatment: [],
  //     PelvicRollsExercise: [],
  //     RecommendationToClient: [],
  //     AwarenessRecommendation: [],
  //     PelvicRollsTreatment: [],
  //     AddressTheFrontalPlaneTreatment: [],
  //     SDChainExercise: [],
  //     StabilizerExercise: [],
  //     OwnBodyWeightExercise: [],
  //     recommendedTreatment: [],
  //     GYMExercise: [],
  //     MotorIntegrationExercise: [],
  //     MotorIntegrationTreatment: [],
  //     AddressCTFlexionExercise: [],
  //     CTFlexionTreatment: [],
  //     AddressTheTMJTreatment: [],
  //     TreatTheNeck: "",
  //     TreatTheNeckExercise: [],
  //     TreatTheTMJ: "",
  //     TreatTheTMJExercise: [],
  //     FinishingExercise: [],
  //     exercisesGiven: [],
  //     AddressTheNeckExercise: [],
  //     NervousSystemExercise: [],
  //     MotorExercise: [],
  //     TMJExercise: [],
  //     DailyExerciseList: [],
  //     PosturalExercise: [],
  //     AllDailyExercise: [],
  //     BreathingExercise: [],
  //     EFTStressExercise: [],
  //     RelaxationExercises: [],
  //     visitDate: moment().format("YY-MM-DD"),
  //     isFunctionalFilled: false,
  //     functionalAnalysys: {
  //       posturalText: "",
  //       straightFeet: "",
  //       straightNote: "",
  //       pelvicTilt: "",
  //       pelvicNote: "",
  //       Necksidebend: "",
  //       NecksidebendNote: "",
  //       Neckrotation: "",
  //       NeckrotationNote: "",
  //       TMJ: "",
  //       TMJNote: "",
  //       ShoulderMobility: "",
  //       ShoulderMobilityNote: "",
  //       Spinal1: "",
  //       Spinal1Note: "",
  //       Spinal2: "",
  //       Spinal2Note: "",
  //       Thoracic: "",
  //       ThoracicNote: "",
  //       HandsBehind: "",
  //       HandsBehindNote: "",
  //       Breathing: "",
  //       BreathingNote: "",
  //       CTFunction: "",
  //       CTFunctionNote: "",
  //       Pelvic: "",
  //       PelvicNote: "",
  //       Motor: "",
  //       MotorNote: "",
  //       otherTest: "",
  //     },
  //   };
  //   setVisits([...visits, temp]);
  // };

  const getClients = async (search) => {
    setLoader(true);
    try {
      const therapistId = auth.user._id;
      const response = await instance.post(
        `${clientRequests.fetchClients}/${therapistId}`,
        {
          search: "",
          count: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (response) {
        let clientsData = response.data.data.list;
        setLoader(false);
        setClientList(clientsData);
      }
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };
  const CheckSmallAndMedium = (mainArray, list) => {
    if (!mainArray) return;
    mainArray.forEach((exe) => {
      const matchedExercise = list.find((i) => i._id === exe._id);
      if (matchedExercise) {
        if (matchedExercise.small) exe.small = true;
        if (matchedExercise.medium) exe.medium = true;
      }
    });
  };

  const arrList = [
    "AddressTheLegsAndHipsExercise",
    "PosturalChainExercise",
    "AddressTheFrontalPlaneExercise",
    "AddressTheArmsShoulderExercise",
    "AddressTheSagittalPlaneExercise",
    "AddressThoracicRotationExercise",
    "AddressErectorImbalancesExercise",
    "PelvicRollsExercise",
    "AddressCTFlexionExercise",
    "SDChainExercise",
    "StabilizerExercise",
    "OwnBodyWeightExercise",
    "GYMExercise",
    "FinishingExercise",
    "RelaxationExercises",
  ];
  useEffect(() => {
    if (ActionButton) {
      const dailyExerciseList = [...program.daily];

      let dailyList = [
        // "620b75e7c77ed601addc32da",// NSI
        // "620b7743c77ed601addc32db",//  MI
        // "620b7af7c77ed601addc32de",//  TMJ
        // "620ed879c77ed601addc3357",//   EFT
        "621295877906020812ed04ea", // SWSF
        "620ac2057906020812ed044b", // SIC
        "620abe9c7906020812ed044a", // AFB
        "62129e707906020812ed04eb", // SQSIT
        "655151e282ea7d7557c14376", //SAW
      ];
      var filteredList = dailyExerciseList.filter(function (e) {
        return dailyList.indexOf(e._id) === -1;
      });
      const mainExerciseList = [...program.list];
      const datarray = [...visits];
      let obj = datarray[visits.length - 1];
      obj["exercisesGiven"] = mainExerciseList;
      obj["visitDate"] = moment().format("YY-MM-DD");
      obj["AllDailyExercise"] = filteredList;
      obj["isSubmitted"] = true;
      arrList.forEach((li) => {
        if (obj[li]) {
          CheckSmallAndMedium(obj[li], mainExerciseList);
        }
      });

      datarray[visits.length - 1] = obj;

      setVisits(datarray);
    }
    checkProgramLimit();
    if (selfDraft) {
      getClients();
    }
  }, []);

  const handleBack = () => {
    let text = program.programId;
    let split_string = text.split(/(\d+)/);
    let programNumber = parseInt(split_string[1]);
    if (program.clientId) {
      history.push(`/client/${client.firstname}/program${programNumber}`);
    } else {
      history.push(`/programs/${auth.user.firstName}/program${programNumber}`);
    }
  };

  const handleAddChip = (chip) => {
    if (isValid(chip)) {
      setRecipients([chip]);
    }
  };

  useEffect(() => {
    dispatch(SET_DRAFT_RECIPIENTS(recipients));
  }, [recipients]);

  const handleDeleteChip = (chipAll, index) => {
    setErrorDataForEmailChip("");
    setErrorForEmailChip(false);
    const dataChip = recipients.filter((chip) => {
      return chip !== chipAll;
    });
    setRecipients(dataChip);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  const isValid = (email) => {
    var error = null;

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setErrorDataForEmailChip(error);
      setErrorForEmailChip(true);
      return false;
    }
    setErrorForEmailChip(false);
    setErrorDataForEmailChip("");
    return true;
  };

  const handleBlurChip = (e) => {
    if (e.target.value.length && isValid(e.target.value)) {
      e.target.value.length && setRecipients([e.target.value]);
    }
  };

  const setProgramhandleChange = (event) => {
    setSelectedProgram(event.target.value);
    if (event.target.value === "online program") {
      setOnlineProgram(true);
      setFreeOnlineProgram(false);
    } else if (event.target.value === "free online program") {
      setOnlineProgram(false);
      setFreeOnlineProgram(true);
    } else {
      setOnlineProgram(false);
      setFreeOnlineProgram(false);
    }
  };

  const updateProgramName = async () => {
    const requestBody = {
      tempProgramId: program.tempProgramId,
      clientId: selfDraft ? selectedClientForProgram?._id : program.clientId,
      therapistId: program.therapistId,
      list: program.list,
      medium: program.medium,
      small: program.small,
      daily: program.daily,
      relax: program?.relax,
      programId:
        tempNameForProgram.length === 0
          ? program.tempProgramId
          : tempNameForProgram,
      isPro: program?.isPro ?? false,
    };
    if (program?.onlineProgramLink || program?.pdfLink) {
      requestBody.edited = true;
      requestBody.editedDate = moment().format();
    }
    try {
      const response = await instance.post(
        `${programRequests.updatePrograms}${program._id}`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      console.log("Response ===> ", response);
      dispatch(SET_PARTICULAR_PROGRAM(response.data.data));
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const updateOnlinePrograms = async () => {
    const requestBody = {
      ...program,
      isFree: freeOnlineProgram,
      subEndDate: TwoMonths,
      subStartDate: subStartDate,
    };
    delete requestBody._id;
    if (program?.onlineProgramLink || program?.pdfLink) {
      requestBody.edited = true;
      requestBody.editedDate = moment().format();
    }
    try {
      const response = await instance.post(
        `${programRequests.updatePrograms}${program._id}`,
        requestBody,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      successToaster(t("Program Updated Successfully"));
      dispatch(SET_PARTICULAR_PROGRAM(response.data.data));
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const checkProgramLimit = async () => {
    try {
      const response = await instance.get(
        `${clientRequests.checkProgramLimit}${auth.user._id}`,
        {
          headers: { Authorization: `Bearer ${auth.token}` },
        }
      );
      if (response.data.data !== undefined) {
        setProgramLimit(response.data.data);
      } else {
        setProgramLimit(0);
      }
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const usingProgramLimit = async () => {
    try {
      if (auth.user.language === "buser") {
        const response = await instance.post(
          `${programRequests.freeProgramForBuser}`,
          {
            customerId: auth.user.customerid,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
      } else {
        const response = await instance.post(
          `${clientRequests.useProgramLimit}`,
          {
            userId: auth.user._id,
          },
          {
            headers: { Authorization: `Bearer ${auth.token}` },
          }
        );
        successToaster(t("Program Limit Used Successfully"));
      }
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };

  const sendPdfToUser = async (key) => {
    // if (ActionButton) {
    //   AddNextVisit();
    // }
    let sendlinkParam = `?clientId=${
      program.clientId ? `${program.clientId}` : ""
    }&ProgramId=${program._id}&therapistId=${auth.user._id}`;

    const requestBody = {
      email:
        key === "downloadPdf"
          ? ""
          : program.clientId
          ? recipients && recipients.length
            ? `${recipients[0]}, ${auth.user.emailAddress}, ${client.email}`
            : `${auth.user.emailAddress}, ${client.email}`
          : recipients && recipients.length
          ? `${recipients[0]}, ${selectedClientForProgram?.email ?? ""}, ${
              auth.user.emailAddress
            }`
          : auth.user.emailAddress,
      comment: draftMsg,
      description:
        onlineProgram || freeOnlineProgram
          ? `https://www.optimum-method.com/#/generatedprogram${sendlinkParam}`
          : // ?  `https://www.optimum-method.com/#/generatedprogram${sendlinkParam}` :
            "",
      language: language,
      // Conditionally add flags based on selectedProgram
      ...(selectedProgram === "only pdf"
        ? key === "downloadPdf"
          ? { isDownloadPdf: true }
          : { isSendPdf: true }
        : {}),
    };

    try {
      await updateProgramName();
      const response = await instance.post(
        `${authRequests.pdfGenrateForuser}${sendlinkParam}`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      if (key === "downloadPdf") {
        setCheckStatus(true);
        setButtonDisable(false);
        setSuccessModalMsg("Pdf successfully downloaded!");
        window.open(response.data.data.path);
        handleSuccessModalOpen();
        dispatch(SET_DRAFT_RECIPIENTS([]));
        dispatch(SET_DRAFT_MESSAGE(""));
      } else {
        setButtonDisable(false);
        setSuccessModalMsg("Program Sent Successfully!");
        handleSuccessModalOpen();
        dispatch(SET_DRAFT_RECIPIENTS([]));
        dispatch(SET_DRAFT_MESSAGE(""));
      }
    } catch (error) {
      if (error.response.data) {
        let message = error.response.data.error.message;
        errorToaster(message);
      }
    }
  };
  const handleSubmit = async () => {
    try {
      let requestBody = {
        Visits: visits,
      };
      if (editClientIdx !== "") {
        const response = await instance.post(
          `${clientRequests.updateClient}/${editClientIdx}`,
          requestBody,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (response) {
          dispatch(SET_PARTICULAR_CLIENT_DATA(response.data.data));
          history.push(`/clients`);
        }
      }
    } catch (error) {
      errorToaster(error.message);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    if (
      successModalMsg === "Program Sent Successfully!" ||
      successModalMsg === "Pdf successfully downloaded!"
    ) {
      if (ActionButton) {
        handleSubmit();
      } else {
        history.push(`/clients`);
      }
    }
  };
  const handleSuccessModalOpen = () => {
    setSuccessModalOpen(true);
  };

  useEffect(() => {
    if (checkStatus === true) {
      setTempNameForProgram(program.programId);
      setCheckStatus(false);
    }
  }, [checkStatus, program]);

  useEffect(() => {
    updateProgramName();
  }, [tempNameForProgram]);

  let allVisit = visits;
  let currentVisit = visits && [visits[allVisit.length - 1]];

  return (
    <>
      <Home>
        <AppHeader
          setTempNameForProgram={setTempNameForProgram}
          tempNameForProgram={tempNameForProgram}
          title={
            selfDraft ? auth.user.firstName : client ? client.firstname : "...."
          }
          handleBack={handleBack}
          backIcon={true}
          type="CreateDraft"
        />
        <div className="create_draft">
          <TextField
            id="description"
            multiline
            rows={8}
            value={draftMsg}
            //placeholder={t(description)}
            placeholder={t("Good luck with the training!")}
            variant="outlined"
            fullWidth
            onChange={(e) => {
              dispatch(SET_DRAFT_MESSAGE(e.target.value));
            }}
          />
          <div className="sent_program_draft">
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
              className="lable"
            >
              {t("The program is sent to")}:
            </Typography>
            {selfDraft ? (
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    _.filter(clientList, (li) => li?._is === program.clientId)
                      .length > 0
                      ? _.filter(
                          clientList,
                          (li) => li?._is === program.clientId
                        )[0]
                      : null
                  }
                  loading={loader}
                  options={clientList.filter((data) => !data?.privateClient)}
                  getOptionLabel={(item) => item?.email}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={(event, newValue) => {
                    setSelectedClientForProgram(newValue);
                  }}
                  renderOption={(props, item) => (
                    <MenuItem {...props}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                        }}
                      >
                        <p
                          style={{ fontSize: 15, fontWeight: "bold" }}
                        >{`${item?.firstname} ${item?.lastname}`}</p>
                        <p style={{ fontSize: 13 }}>{item?.email}</p>
                      </div>
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // to disable browser autofill
                      }}
                    />
                  )}
                />
              </FormControl>
            ) : (
              <TextField
                margin="dense"
                className="client_email"
                id="client_email"
                type="text"
                name="clientEmail"
                variant="outlined"
                fullWidth
                disabled={client.privateClient && true}
                value={program.clientId ? client.email : auth.user.emailAddress}
              />
            )}
          </div>
          <div className="sent_program_draft">
            <Typography
              variant="subtitle1"
              display="block"
              gutterBottom
              className="lable"
            >
              {t("Add additional recipients")}:
            </Typography>
            <ChipInput
              error={ErrorForEmailChip}
              helperText={ErrorForEmailChip && ErrorDataForEmailChip}
              className="recipients"
              value={recipients}
              variant="outlined"
              fullWidth
              onAdd={(chip) => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
              onBlur={(e) => {
                handleBlurChip(e);
              }}
              onUpdateInput={() => {
                setErrorForEmailChip(false);
                setErrorDataForEmailChip("");
              }}
            />
          </div>
          <div className="online_program_group">
            <div className="online_program">
              {/* <div className="program_checkbox_container">
                {auth.user.language !== "buser" && (
                  <FormControlLabel
                    className={classes.label}
                    control={
                      <Checkbox
                        checked={onlineProgram}
                        onChange={(e) => {
                          setOnlineProgram(e.target.checked);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={t("Online Program")}
                  />
                )}
                <div className="freeProgram">
                  {auth.user.language !== "buser" ? (
                    <>
                      <FormControlLabel
                        className={classes.label}
                        disabled={programLimit <= 0 ? true : false}
                        control={
                          <Checkbox
                            checked={freeOnlineProgram}
                            onClick={(e) => {
                              setFreeOnlineProgram(e.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label={t("Free Online Program")}
                      />
                      <Chip
                        disabled={programLimit <= 0 ? true : false}
                        label={programLimit + " " + t("Programs remaining")}
                        style={{
                          color: programLimit > 2 ? "#2e7d32" : "#d32f2f",
                        }}
                        variant="outlined"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        className={classes.label}
                        control={
                          <Checkbox
                            checked={freeOnlineProgram}
                            onClick={(e) => {
                              setFreeOnlineProgram(e.target.checked);
                            }}
                            color="primary"
                          />
                        }
                        label={t("Free Online Program")}
                      />
                    </>
                  )}
                </div>
              </div> */}
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedProgram}
                onChange={setProgramhandleChange}
              >
                {auth.user.language !== "buser" && (
                  <FormControlLabel
                    value="online program"
                    control={<Radio style={{ color: "dodgerblue" }} />}
                    label={t("Online Program")}
                  />
                )}
                <div className="freeProgram">
                  {auth.user.language !== "buser" ? (
                    <>
                      <FormControlLabel
                        disabled={programLimit <= 0 ? true : false}
                        value="free online program"
                        control={<Radio style={{ color: "dodgerblue" }} />}
                        label={t("Free Online Program")}
                      />
                      <Chip
                        disabled={programLimit <= 0 ? true : false}
                        label={programLimit + " " + t("Programs remaining")}
                        style={{
                          color: programLimit > 2 ? "#2e7d32" : "#d32f2f",
                        }}
                        variant="outlined"
                      />
                    </>
                  ) : (
                    <>
                      <FormControlLabel
                        value="free online program"
                        control={<Radio style={{ color: "dodgerblue" }} />}
                        label={t("Free Online Program")}
                      />
                    </>
                  )}
                </div>
                <FormControlLabel
                  value="only pdf"
                  control={<Radio style={{ color: "dodgerblue" }} />}
                  label={t("Only PDF")}
                />
              </RadioGroup>
              <div className="online_program_info">
                <InfoIcon className="info_icon" onClick={handleModalOpen} />
              </div>
            </div>
            {ActionButton && (
              <div className="visitPdfButtonContainer">
                <PDFDownloadLink
                  document={
                    <MyDoc
                      client={ClientData}
                      data={currentVisit}
                      OptimumMindVisits={OptimumMindVisits}
                      piercingValue={piercingValue}
                      waterIntakeAndCoffeeValue={waterIntakeAndCoffeeValue}
                      sleepValue={sleepValue}
                      habitualValue={habitualValue}
                      monotonouseValue={monotonouseValue}
                      workdayValue={workdayValue}
                      irtProblem={irtProblem}
                      eftProblem={eftProblem}
                    />
                  }
                  fileName={`${ClientData?.firstname}_${moment().format(
                    "YYYY-MM-DDThh:mm:ss"
                  )}_current-visit.pdf`}
                >
                  {({ blob, url, loading, error }) =>
                    loading ? "Loading document..." : t("PDF-printout of visit")
                  }
                </PDFDownloadLink>

                {/* <PDFDownloadLink
                  document={
                    <MyDoc
                      data={allVisit}
                      piercingValue={piercingValue}
                      waterIntakeAndCoffeeValue={waterIntakeAndCoffeeValue}
                      sleepValue={sleepValue}
                      habitualValue={habitualValue}
                      monotonouseValue={monotonouseValue}
                      workdayValue={workdayValue}
                      irtProblem={irtProblem}
                      eftProblem={eftProblem}
                    />
                  }
                  fileName="All-visits.pdf"
                >
                  {({ blob, url, loading, error }) =>
                    loading
                      ? "Loading document..."
                      : t("PDF-printout of all visits")
                  }
                </PDFDownloadLink> */}
              </div>
            )}
          </div>

          <div className="update_button">
            <Button
              variant="contained"
              className="send_pdf"
              disabled={buttonDisable}
              onClick={() => {
                setButtonDisable(true);
                sendPdfToUser("sendPdf");
                if (freeOnlineProgram) {
                  updateOnlinePrograms();
                  usingProgramLimit();
                }
              }}
            >
              {/* {t("Send Program")} */}
              <img
                className="img_icon"
                src="https://img.icons8.com/material-outlined/64/ffffff/send-mass-email.png"
              />
            </Button>
            <Button
              variant="contained"
              className="Pdf_Preview"
              disabled={buttonDisable}
              onClick={() => {
                setButtonDisable(true);
                sendPdfToUser("downloadPdf");
              }}
            >
              {/* {t("Pdf Preview")} */}
              <img
                className="img_icon"
                src="https://img.icons8.com/pastel-glyph/64/ffffff/file-preview--v2.png"
              />
            </Button>
          </div>
        </div>
        <DialogBox
          title={t("Online Program")}
          open={modalOpen}
          handleOK={handleCloseModal}
          type="okClose"
        >
          <div className="OOPDescription">{t("OOP_Info_Description")}</div>
        </DialogBox>
        {successModalMsg && (
          <SuccessDialogBox
            open={successModalOpen}
            //handleOK={handleCloseSuccessModal}
            type="okClose"
          >
            <CheckCircleOutlineRoundedIcon className="success_icon" />
            <br />
            <p>{t(`${successModalMsg}`)}</p>
            <div className="ok_button">
              <Button onClick={handleCloseSuccessModal} variant="contained">
                {t("Ok")}
              </Button>
            </div>
          </SuccessDialogBox>
        )}
      </Home>
      <Menubar />
    </>
  );
}

export default CreateDraft;
