import { REHYDRATE } from "redux-persist";

let initState = {
  language: "swedish",
  email: "",
  otp: "",
  planId: null,
  subscribed: false,
  userData: "",
  auth: "",
  subscriptionId: null,
  hardReload: false,
  allClients: [],
  editClientIdx: "",
  particularClientData: {},
  particularProgramData: {},

  editExerciseData: {},
  exerciseDescription: [],
  tabIndexForLink: 0,
  jsonData: {},
  tempUserData: {},
  draftMsg: "",
  draftRecipients: [],
  searchVal: "",
  searchActiveStatus: false,
  inputFocus: false,
  allExercise: [],
  setCurrentVersion: { currentVersion: "large", currentVersionIndex: 3 },
  setCurrentVersionForOnlineProgram: 0,
  draftMessage: "",
  sortStatus: 1,
  statusFromExeDesc: false,
  tempallCkeckedExe: [],
  tempClientData: {},
  setParticularClient: {},
  dailyExercise: [],
  exerciseList: {},
  selectedExercise: [],
  actionSummaryButton: false,
  scrollUp: false,
  clientLink: "",
  selectedScroll: "",
  selectedOOPlanId: "",
  reLogin: false,
  visitSendFromAnother: false,
  newVisitCreated: false,
  activeExerciseAndTreatmentTab: 0,
  buserToken: "",
  exerciseAndTreamentType: "",
  particularBuserProgramData: {
    OPstatus: true,
    list: [],
    small: [],
    medium: [],
    daily: [],
    relax: [],
    pdfLink: "",
    onlineProgramLink: "",
    programId: "Sequence",
    tempProgramId: "Sequence",
    therapistId: "",
    clientId: "",
    clinic: "",
    clinicId: "",
    visitNo: "",
    columnNo: "",
    isEditPublish: false,
  },
};

function optimumReducer(state = initState, action) {
  switch (action.type) {
    case "SELECT_LANGUAGE":
      return {
        ...state,
        language: action.res,
      };
    case "TEMP_USER_DATA":
      return {
        ...state,
        tempUserData: action.res,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.res,
      };
    case "SET_PLAN_ID":
      return {
        ...state,
        planId: action.res,
      };
    case "SET_SUBSCRIPTION_ID":
      return {
        ...state,
        subscriptionId: action.res,
      };
    case "SET_SUBSCRIBED_STATUS":
      return {
        ...state,
        subscribed: action.res,
      };
    case "USER_DATA":
      return {
        ...state,
        userData: action.res,
      };
    case "SET_OTP":
      return {
        ...state,
        otp: action.res,
      };
    case "SET_AUTH":
      return {
        ...state,
        auth: action.res,
      };
    case "HARD_RELOAD":
      return {
        ...state,
        hardReload: action.res,
      };
    case "SET_CLIENT_DATA":
      return {
        ...state,
        allClients: action.res,
      };
    case "SET_EDIT_EXERCISE_DATA":
      return {
        ...state,
        editExerciseData: action.res,
      };
    case "SET_CLIENT_PROGRAMS":
      return {
        ...state,
        allPrograms: action.res,
      };
    case "SET_PARTICULAR_CLIENT":
      return {
        ...state,
        particularClientData: action.res,
      };
    case "SET_PARTICULAR_PROGRAM":
      return {
        ...state,
        particularProgramData: action.res,
      };
    case "EXERCISE_DESCRIPTION":
      return {
        ...state,
        exerciseDescription: action.res,
      };
    case "EDIT_CLIENT_IDX":
      return {
        ...state,
        editClientIdx: action.res,
      };
    case "TAB_INDEX_FOR_PDF_LINK":
      return {
        ...state,
        tabIndexForLink: action.res,
      };
    case "STORE_JSON_DATA":
      return {
        ...state,
        jsonData: action.res,
      };
    case "SET_DRAFT_MESSAGE":
      return {
        ...state,
        draftMsg: action.res,
      };
    case "SET_DRAFT_RECIPIENTS":
      return {
        ...state,
        draftRecipients: action.res,
      };
    case "SET_SEARCH_INPUT_VALUE":
      return {
        ...state,
        searchVal: action.res,
      };
    case "SET_SEARCH_ACTIVE_STATUS":
      return {
        ...state,
        searchActiveStatus: action.res,
      };
    case "SET_SEARCH_FOCUS":
      return {
        ...state,
        inputFocus: action.res,
      };
    case "SET_ALL_EXERCISE":
      return {
        ...state,
        allExercise: action.res,
      };
    case "SET_CURRENT_VIRSION":
      return {
        ...state,
        setCurrentVersion: action.res,
      };
    case "SET_CURRENT_VIRSION_INDEX_FOR_ONLINE_PROGRAM":
      return {
        ...state,
        setCurrentVersionForOnlineProgram: action.res,
      };
    case "SET_SORT_STATUS":
      return {
        ...state,
        sortStatus: action.res,
      };
    case "SET_BACK_STATUS_FROM_EXE_DESC":
      return {
        ...state,
        statusFromExeDesc: action.res,
      };
    case "TEMP_ALL_CHECKED_EXE":
      return {
        ...state,
        tempallCkeckedExe: action.res,
      };
    case "TEMP_CLIENT_DATA":
      return {
        ...state,
        tempClientData: action.res,
      };
    case "SET_PARTICULAR_CLIENT_DATA":
      return {
        ...state,
        setParticularClient: action.res,
      };

    case "SET_DAILY_EXE":
      return {
        ...state,
        dailyExercise: action.res,
      };

    case "SET_EXERCISE_LIST_DATA":
      return {
        ...state,
        exerciseList: action.res,
      };

    case "SELECTED_EXERCISE_LIST":
      return {
        ...state,
        selectedExercise: action.res,
      };

    case "SHOW_ACTION_BUTTON":
      return {
        ...state,
        actionSummaryButton: action.res,
      };

    case "SET_SCROLL":
      return {
        ...state,
        scrollUp: action.res,
      };

    case "SET_SELECTED_SCROLL":
      return {
        ...state,
        selectedScroll: action.res,
      };

    case "SET_CLIENT_LINK":
      return {
        ...state,
        clientLink: action.res,
      };

    case "SELECT_OOPLAN":
      return {
        ...state,
        selectedOOPlanId: action.res,
      };

    case "RE_LOGIN":
      return {
        ...state,
        reLogin: action.res,
      };
    case "VISIT_SEND_FROM_ANOTHER_DEVICE":
      return {
        ...state,
        visitSendFromAnother: action.res,
      };
    case "NEW_VISIT_CREATED":
      return {
        ...state,
        newVisitCreated: action.res,
      };
    case "ACTIVE_EXERCISE_AND_TREATMEN_TAB":
      return {
        ...state,
        activeExerciseAndTreatmentTab: action.res,
      };
    case "SET_BUSERTOKEN":
      return {
        ...state,
        buserToken: action.res,
      };
    case "SET_PARTICULAR_BUSER_PROGRAM":
      return {
        ...state,
        particularBuserProgramData: action.res,
      };
    case "SET_EXERCISE_AND_TREATMENT_TYPE":
      return {
        ...state,
        exerciseAndTreamentType: action.res,
      };
    case "SET_IDS_BUSER_PROGRAM":
      return {
        ...state,
        particularBuserProgramData: {
          ...state.particularBuserProgramData,
          therapistId: action.res.therapistId,
          clientId: action.res.clientId,
          clinicId: action.res.clinicId,
          columnNo: action.res.columnNo,
          visitNo: action.res.visitNo,
          isEditPublish: action.res.isEditPublish,
        },
      };
    case "SET_BUSER_PROGRAM":
      return {
        ...state,
        particularBuserProgramData: {
          ...state.particularBuserProgramData,
          list: action.res?.list,
          small: action.res?.small,
          medium: action.res?.medium,
          daily: action.res?.daily,
          relax: action.res?.relax ?? [],
        },
      };
    case "EMPTY_PARTICULAR_BUSER_PROGRAM":
      return {
        ...state,
        particularBuserProgramData: {
          OPstatus: true,
          list: [],
          small: [],
          medium: [],
          daily: [],
          relax: [],
          pdfLink: "",
          onlineProgramLink: "",
          programId: "Sequence",
          tempProgramId: "Sequence",
          therapistId: "",
          clientId: "",
          clinicId: "",
          visitNo: "",
          columnNo: "",
          isEditPublish: false,
        },
      };
    case "LOG_OUT":
      return {
        ...state,
        auth: "",
        allClients: [],
        allPrograms: [],
        editClientIdx: "",
        hardReload: false,
        otp: "",
        userData: "",
        particularClientData: {},
        tabIndexForLink: 0,
        // particularProgramData:{},
        email: "",
        subscribed: false,
        setCurrentVersion: {
          currentVersion: "large",
          currentVersionIndex: 3,
        },
        draftMessage: "",
        sortStatus: 1,
        statusFromExeDesc: false,
        tempClientData: {},
        setParticularClient: {},
        visits: [],
        exerciseList: {},
        selectedExercise: [],
        actionSummaryButton: false,
        scrollUp: false,
        selectedScroll: "",
        selectedOOPlanId: "",
        reLogin: false,
        visitSendFromAnother: false,
        newVisitCreated: false,
        activeExerciseAndTreatmentTab: 0,
      };
    case REHYDRATE:
      return {
        ...state,
        language:
          action.payload && action.payload.language
            ? action.payload.language
            : "swedish",
        // language:
        //   action.payload && action.payload.language
        //     ? action.payload.language
        //     : "english",
        email:
          action.payload && action.payload.email ? action.payload.email : "",
        otp: action.payload && action.payload.otp ? action.payload.otp : "",
        particularBuserProgramData:
          action.payload && action.payload.particularBuserProgramData
            ? action.payload.particularBuserProgramData
            : {
                OPstatus: true,
                list: [],
                small: [],
                medium: [],
                daily: [],
                relax: [],
                pdfLink: "",
                onlineProgramLink: "",
                programId: "Sequence",
                tempProgramId: "Sequence",
                therapistId: "",
                clientId: "",
                clinicId: "",
                visitNo: "",
                columnNo: "",
                isEditPublish: false,
              },
        buserToken:
          action.payload && action.payload.buserToken
            ? action.payload.buserToken
            : "",
        planId:
          action.payload && action.payload.planId
            ? action.payload.planId
            : null,
        subscribed:
          action.payload && action.payload.subscribed
            ? action.payload.subscribed
            : false,
        subscriptionId:
          action.payload && action.payload.subscriptionId
            ? action.payload.subscriptionId
            : null,
        auth: action.payload && action.payload.auth ? action.payload.auth : "",
        hardReload:
          action.payload && action.payload.hardReload
            ? action.payload.hardReload
            : false,
        allClients:
          action.payload && action.payload.allClients
            ? action.payload.allClients
            : [],
        particularClientData:
          action.payload && action.payload.particularClientData
            ? action.payload.particularClientData
            : {},
        editExerciseData:
          action.payload && action.payload.editExerciseData
            ? action.payload.editExerciseData
            : {},
        particularProgramData:
          action.payload && action.payload.particularProgramData
            ? action.payload.particularProgramData
            : {},
        editClientIdx:
          action.payload && action.payload.editClientIdx
            ? action.payload.editClientIdx
            : "",
        tabIndexForLink:
          action.payload && action.payload.tabIndexForLink
            ? action.payload.tabIndexForLink
            : 0,
        userData:
          action.payload && action.payload.userData
            ? action.payload.userData
            : "",
        exerciseDescription:
          action.payload && action.payload.exerciseDescription
            ? action.payload.exerciseDescription
            : "",
        jsonData:
          action.payload && action.payload.jsonData
            ? action.payload.jsonData
            : {},
        tempUserData:
          action.payload && action.payload.tempUserData
            ? action.payload.tempUserData
            : {},
        draftMsg:
          action.payload && action.payload.draftMsg
            ? action.payload.draftMsg
            : "",
        draftRecipients:
          action.payload && action.payload.draftRecipients
            ? action.payload.draftRecipients
            : [],
        searchVal:
          action.payload && action.payload.searchVal
            ? action.payload.searchVal
            : "",
        searchActiveStatus:
          action.payload && action.payload.searchActiveStatus
            ? action.payload.searchActiveStatus
            : false,
        inputFocus:
          action.payload && action.payload.inputFocus
            ? action.payload.inputFocus
            : false,
        allExercise:
          action.payload && action.payload.allExercise
            ? action.payload.allExercise
            : [],
        setCurrentVersion:
          action.payload && action.payload.setCurrentVersion
            ? action.payload.setCurrentVersion
            : { currentVersion: "large", currentVersionIndex: 3 },
        setCurrentVersionForOnlineProgram:
          action.payload && action.payload.setCurrentVersionForOnlineProgram
            ? action.payload.setCurrentVersionForOnlineProgram
            : 0,
        draftMessage:
          action.payload && action.payload.draftMessage
            ? action.payload.draftMessage
            : "",
        sortStatus:
          action.payload && action.payload.sortStatus
            ? action.payload.sortStatus
            : 1,
        statusFromExeDesc:
          action.payload && action.payload.statusFromExeDesc
            ? action.payload.statusFromExeDesc
            : false,
        tempallCkeckedExe:
          action.payload && action.payload.tempallCkeckedExe
            ? action.payload.tempallCkeckedExe
            : [],
        tempClientData:
          action.payload && action.payload.tempClientData
            ? action.payload.tempClientData
            : {},
        setParticularClient:
          action.payload && action.payload.setParticularClient
            ? action.payload.setParticularClient
            : {},

        dailyExercise:
          action.payload && action.payload.dailyExercise
            ? action.payload.dailyExercise
            : [],

        exerciseList:
          action.payload && action.payload.exerciseList
            ? action.payload.exerciseList
            : {},

        selectedExercise:
          action.payload && action.payload.selectedExercise
            ? action.payload.selectedExercise
            : [],

        actionSummaryButton:
          action.payload && action.payload.actionSummaryButton
            ? action.payload.actionSummaryButton
            : false,

        scrollUp:
          action.payload && action.payload.scrollUp
            ? action.payload.scrollUp
            : false,

        selectedScroll:
          action.payload && action.payload.selectedScroll
            ? action.payload.selectedScroll
            : "",

        clientLink:
          action.payload && action.payload.clientLink
            ? action.payload.clientLink
            : "",
        selectedOOPlanId:
          action.payload && action.payload.selectedOOPlanId
            ? action.payload.selectedOOPlanId
            : "",
        reLogin:
          action.payload && action.payload.reLogin
            ? action.payload.reLogin
            : false,
        visitSendFromAnother:
          action.payload && action.payload.visitSendFromAnother
            ? action.payload.visitSendFromAnother
            : false,
        newVisitCreated:
          action.payload && action.payload.newVisitCreated
            ? action.payload.newVisitCreated
            : false,
        activeExerciseAndTreatmentTab:
          action.payload && action.payload.activeExerciseAndTreatmentTab
            ? action.payload.activeExerciseAndTreatmentTab
            : 0,
        exerciseAndTreamentType:
          action.payload && action.payload.exerciseAndTreamentType
            ? action.payload.exerciseAndTreamentType
            : "",
      };
    default:
      return {
        ...state,
      };
  }
}

export const reducer = optimumReducer;
